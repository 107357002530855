/**
* @file Provides functionality for Carousels.
*/
(function() {
  jQuery('.carousel .slides').not('.slick-initialized').slick({
    lazyLoad: 'ondemand',
    slidesToScroll: 1,
    accessibility: true,
    autoplay: false,
    infinite: true,
    variableWidth: true,
    prevArrow: '.carousel .controls .prev',
    nextArrow: '.carousel .controls .next'
  });
})();
