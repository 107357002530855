/**
* @file Provides additional functionality for Accordions.
*/

jQuery('.accordion-body').on('show.bs.collapse', function(e) {
  // Delay until after accordion transitions
  setTimeout(function() {
    const $header = jQuery('.global-header');
    const $body = jQuery(e.target);
    const $section = $body.parent();

    // Scroll to the newly opened section
    const t = $section.offset().top - $header.outerHeight();
    jQuery([document.documentElement, document.body]).animate({scrollTop: t}, 0);
  }, 350);
});
