/**
* @file Provides functionality for Main Menu.
*/
(function() {

  const mobileBreakPoint = window.bp.mobileNav;
  const $body = $('body');
  const $header = $('#global-header');
  const $navItem = $('.nav-item');
  const $toggleNavs = jQuery('.global-header .dropdown-toggle');
  const $MenuToggle = $('#navbar-toggle');
  const $mainNav = $header.find('.main-nav');
  const $utilityNav = $header.find('.utility-nav');

  /**
   * Checks if this is a Desktop sized viewport based on Modernizr media query
   *
   * @return {boolean} Result of Modernizr media query
   */
  function isDesktopNav() {
    let isDesktop = false;
    if (typeof Modernizr !== 'undefined') {
      isDesktop = Modernizr.mq(`(min-width: ${mobileBreakPoint}px)`);
    }
    else {
      isDesktop = window.innerWidth > mobileBreakPoint;
    }
    return isDesktop;
  }

  // add/remove a class to alter the behavior of dropdowns on mobile
  $toggleNavs.click(function(event) {

    var $nav = jQuery(this);
    if (window.innerWidth < mobileBreakPoint && $nav.attr('aria-expanded') === 'false') {
      jQuery('.global-header').addClass('mobile-subnav');
    }
    else {
      jQuery('.global-header').removeClass('mobile-subnav');
    }
  });

  // nodes to hide from mobile screen readers
  // all top level nodes, except header (those are cherry picked)
  // https://github.com/focus-trap/focus-trap/issues/80
  const $nodeToAriaHide = $body
    .children(':not(script):not(.global-header)')
    .add($body
      .children('.global-header')
      .find('.header-search-mobile, .header-logo, .header-utility')
    );

  // Initialize focus trap
  const trap = focusTrap.createFocusTrap($header[0], {
    onActivate: function() {
      handleTrapFocusActivate();
    },
    onDeactivate: function() {
      handleTrapFocusDeactivate();
    }
  });

  function fitDropDownV($dropdown) {
    if ($dropdown.hasClass('overflow')) {
      $dropdown.removeClass('overflow');
    }
    // reset inline styles
    $dropdown.css({'transform': '', 'top': '', 'max-height': '', 'overflow': ''});
    // position the top of the dropdown to the bottom of the parent
    let pT = $dropdown.parent().height();
    $dropdown.css({'top': pT + 'px'});
    // ensure dropdown isn't larger than the window
    let dT = $dropdown[0].getBoundingClientRect().y;
    let dH = $dropdown.outerHeight();
    let wH = window.innerHeight;
    if (wH < (dT + dH)) {
      $dropdown.addClass('overflow');
      $dropdown.css({'max-height': (wH - dT - 50) + 'px', 'overflow': 'auto'});
    }
  }

  function fitDropDownRight($dropdown) {
    const $item = $dropdown.parent();
    const $nav = $item.parent();

    const r = $item.offset().left + $nav.offset().left + $dropdown.outerWidth();
    let l = parseFloat($dropdown.css('left'));

    if (r > window.innerWidth) {
      // move subnav to the left so it no longer extends off-screen
      l = Math.floor(l - (r - window.innerWidth) - 10);
      $dropdown.css('left', `${l}px`);
    }
  }

  window.addEventListener('resize', window.debounce(handleTransitionToDesktop, 250));

  // Main menu sub menu shown
  $navItem.on('show.bs.dropdown', function() {
    const $item = jQuery(this);
    const $dropdown = $item.find('>.dropdown-menu');
    $header.addClass('has-open-nav-item');


    // make sure everything is visible vertically too
    setTimeout(function() {
      if (isDesktopNav()) {
        fitDropDownRight($dropdown);
        fitDropDownV($dropdown);
      }
    }, 0);

  });

  // Main menu sub menu collapsed
  $navItem.on('hide.bs.dropdown', function(e) {
    var $focused = jQuery(':focus');
    if ($focused.closest('.global-header').length === 0 || isDesktopNav()) {
      $header.removeClass('has-open-nav-item');
    }
    else { // focus is in the expanded menu
      e.preventDefault();
    }
  });

  // Main menu shown
  $header.on('show.bs.collapse', function() {
    setTimeout(function() {
      trap.activate();
      // focus on first elem in trap, timeout to allow for show
      $MenuToggle.trigger('focus');
    }, 1);
  });

  // Main menu collapsed
  $header.on('hide.bs.collapse', function() {
    setTimeout(function() {
      $header.find('.navbar-nav .show').collapse('hide');
      $header.removeClass('mobile-subnav');
      trap.deactivate();
      $MenuToggle.trigger('focus');
    }, 1);
  });

  // close menu if scrim is clicked
  $body.on('click', function(e) {

    if ($(e.target).hasClass('scrim')) {
      trap.deactivate();
    }
  });

  function setDesktopNavWidths() {
    const $navItems = $header.find('>.navbar-nav >.nav-item, .secondary-nav >.navbar-nav >.nav-item');
    $navItems.css({'width': ''});
    if (isDesktopNav()) {
      $navItems.each(function(i) {
        const $item = $(this);
        const $link = $item.find('>.nav-link').first();
        $link.css({'display': 'inline'});
        let w = $link.width() + 5;
        $item.css({'width': `${w}px`});
        $link.css({'display': ''});
      });
    }
  }

  /**
   * Handle logic for transition to Desktop
   */
  function handleTransitionToDesktop() {
    if (isDesktopNav() && $header.hasClass('show')) {
      trap.deactivate();
      $header.removeClass('show');
    }
  }

  /**
   * Handle logic for focus trap activation
   */
  function handleTrapFocusActivate() {
    $nodeToAriaHide
      .attr('aria-hidden', true)
      .css('cursor', 'default');
    $header.addClass('trap-active');
    $body.addClass('main-nav-open');
  }

  /**
   * Handle logic for focus trap deactivation
   */
  function handleTrapFocusDeactivate() {
    $nodeToAriaHide
      .removeAttr('aria-hidden')
      .removeAttr('style');
    $header.removeClass('trap-active');
    // hide all menus on escape
    $header.collapse('hide');
    $body.removeClass('main-nav-open');
  }

  function fixNavTabOrder() {
    if (window.innerWidth >= 1300) {
      $utilityNav.insertAfter($mainNav);
    }
    else {
      $mainNav.insertAfter($utilityNav);
    }
  }
  let resizeTO = null;
  jQuery(window).resize(function() {
    const $active = jQuery(document.activeElement);
    clearTimeout(resizeTO);
    resizeTO = setTimeout(function() {
      fixNavTabOrder();
      setDesktopNavWidths();
      if (!$active.hasClass('st-default-search-input')) {
        jQuery('.dropdown-menu.show, .nav-item.show').removeClass('show');
        if (!isDesktopNav()) {
          $header.find('.collapse').collapse('hide');
          $header.removeClass('mobile-subnav');
        }
      }
      // close any open subnavs

    }, 10);
  });

  let scrollTO = null;
  jQuery(window).scroll(function() {
    clearTimeout(scrollTO);
    scrollTO = setTimeout(function() {
      // close any open subnavs
      jQuery('.dropdown-menu.show, .nav-item.show').removeClass('show');
    }, 10);
  });

  jQuery(document).ready(function() {
    fixNavTabOrder();
    jQuery('.main-nav .dropdown-toggle').each(function() {
      var $item = jQuery(this);
      if ($item.text().trim().toLowerCase() === 'who we are') {
        $item.css({'white-space': 'nowrap'});
      }
    });
    setDesktopNavWidths();
  });
})();
