/**
* @file Provides functionality for Cards.
*/
(function() {
  let resizeTO = null;

  const cardsEqualHeaderHeight = function() {
    jQuery('.card-wrap:first-child').each(function(i) {
      const $first = jQuery(this);
      const $cards = $first.parent().find('.card-wrap');
      $cards.find('.card-header').css({'height': ''});
      // Group cards by row, using offset top to determine
      const rows = [
        []
      ];
      let oT = $first.offset().top;
      $cards.each(function(j) {
        const $card = jQuery(this);
        if ($card.offset().top === oT) {
          rows[rows.length - 1].push($card);
        }
        else {
          rows.push([$card]);
          oT = $card.offset().top;
        }
      });
      // Normalize header height on each row

      rows.forEach(row => {
        if (row.length > 1) {
          let hh = 0;
          // Get the tallest header
          row.forEach($card => {
            hh = Math.max(hh, $card.find('.card-header').height());
          });
          // Set all headers to match tallest
          row.forEach($card => {
            $card.find('.card-header').css({'height': `${hh}px`});
          });
        }
      });
    });
  };

  // Remove collapse markup when there is no content
  const removeExpandWhenEmpty = function() {
    jQuery('.collapse').each(function() {
      const $collapse = jQuery(this);
      const id = $collapse.attr('id').trim();
      const $btn = jQuery(`[data-toggle="collapse"][data-target="#${id}"]`);
      const text = $collapse.text().trim();
      if (!text) {
        $btn.remove();
        $collapse.remove();
      }
    });
  };

  jQuery(document).ready(function() {
    cardsEqualHeaderHeight();
    removeExpandWhenEmpty();
  });


  jQuery(window).resize(function() {
    clearTimeout(resizeTO);
    resizeTO = setTimeout(function() {
      cardsEqualHeaderHeight();
    }, 100);
  });
})();
