// set a variable to the window for cache busting verification
window.acfasScriptV = '2.3';
// make breakpoints common to all scripts
window.bp = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1300
};

window.bp.mobileNav = window.bp.lg;
/**
 * Calls a function when the time is up
 *
 * @param {function} func The function to debounce
 * @param {number} wait Time to wait in milliseconds
 * @param {boolean} immediate Trigger now
 */
const debounce = function(func, wait, immediate) {
  var timeout;

  return function() {
    var self = this;
    var args = arguments;

    var later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(self, args);
      }
    };

    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(self, args);
    }
  };
};

window.debounce = debounce;

/**
* Test an event to see if it is a click or a return/spacebar keypress.
* For Accessibility purposes.
*
* @param {event} Event to test
*/
const a11yClick = function(event) {
  let code = event.charCode || event.keyCode;
  if (event.type === 'click') {
    return true;
  }
  else if (event.type === 'keypress') {
    if ((code === 32) || (code === 13)) {
      return true;
    }
  }
  else {
    return false;
  }
};
window.a11yClick = a11yClick;

// Hide search results on scroll, since we can't override JS to make them fixed.
const searchResultScroll = function() {
  if (window.innerWidth > window.bp.mobileNav) {
    jQuery('.st-default-search-input').blur();
    jQuery('.st-default-autocomplete').css({'display': 'none'});
  }
};

window.addEventListener('scroll', window.debounce(searchResultScroll, 5));

